<template lang="">
    <div class="atlas-map-wrapper">
        <atlas-map ref="atlasMap"/>
        <transition name="slide-fade">
            <left-sidebar v-show="isLeftSidebarVisible" id="left-sidebar"/>
        </transition>
        <transition name="slide-fade-right">
            <right-sidebar v-if="isRightSidebarVisible" id="right-sidebar"/>
        </transition>
    </div>
</template>
<script>
import AtlasMap from '@/modules/atlas/components/AtlasMap';
import LeftSidebar from '@/modules/atlas/components/LeftSidebar';
import RightSidebar from '@/modules/atlas/components/RightSidebar';
import { mapGetters, mapActions } from 'vuex';
import Roles from '@/security/roles';

export default {
    name: 'Atlas',
    components: {
        AtlasMap,
        LeftSidebar,
        RightSidebar,
    },
    computed: {
        ...mapGetters({
            isLeftSidebarVisible: 'atlas/sidebar/isVisible',
            isRightSidebarVisible: 'atlas/layer-manager/isSublayerSidebarVisible',
            isStaff: 'auth/isStaff',
        }),
    },
    methods: {
        ...mapActions({
            initSidebarState: 'atlas/sidebar/doInit',
            initLayersState: 'atlas/layer-manager/doInit',
        })
    },
    async created() {
        if (!this.isStaff) {
            this.$router.push({ name: 'home'})
        }
        await this.initSidebarState();
        await this.initLayersState();
    },
}
</script>
<style lang="scss">
    .atlas-map-wrapper {
        height: 100%;
        margin: 0 -12px;
    }
    .slide-fade-enter-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-leave-active {
        transition: all .3s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(-100%);
    }
    .slide-fade-leave-to {
        opacity: 0;
    }
    .slide-fade-right-enter-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-right-leave-active {
        transition: all .3s ease;
    }
    .slide-fade-right-enter, .slide-fade-right-leave-to {
        transform: translateX(100%);
    }
    .slide-fade-right-leave-to {
        opacity: 0;
    }
</style>
