<template>
    <div>
        <portal to="atlas-sublayer-sidebar-title">
          <span v-b-popover.hover="description">{{ layer.name }}</span>
        </portal>
        <div class="sublayer-container">
          <div class="row sublayer-column-header">
            <div class="col-8 text-left p-0">{{ $t('ATLAS.SUBLAYER_MANAGER.SUBLAYER_NAME') }}</div>
            <div class="col-4 text-right p-0">{{ $t('ATLAS.SUBLAYER_MANAGER.SUBLAYER_ACTIONS') }}</div>
          </div>
          <template v-if="selectedLayer.sublayers && selectedLayer.sublayers.length">
            <div class="sublayer-item row" v-for="sublayer in selectedLayer.sublayers" :key="sublayer.id">
              <div class="col-8 sublayer-name">{{ sublayer.name }}</div>
              <div class="col-4 sublayer-actions">
                <b-form-checkbox
                  :checked="sublayer.visible"
                  @change="toggleSublayer($event, sublayer.gis_id)"
                  switch
                  size="sm"
                ></b-form-checkbox>
                <!-- TODO: uncomment once functionality is implemented -->
                <!-- <i class="fas fa-map-marked-alt"></i>
                <i class="fas fa-eye"></i> -->
              </div>
            </div>
          </template>
            <div v-else class="text-center">{{ $t('ATLAS.SUBLAYER_MANAGER.EMPTY_SUBLAYERS_TEXT') }}</div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'SublayerManager',
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters({
        layer: 'atlas/layer-manager/layer',
        selectedLayers: 'atlas/layer-manager/selectedLayers',
        getSublayerVisibilityById: 'atlas/layer-manager/getSublayerVisibilityById',
      }),
      selectedLayer() {
          let sublayers = {}
            if (this.selectedLayers?.[this.layer.gis_layer_id]?.sublayers) {
                sublayers = this.layer.sublayers.map((layer) => {
                    return {
                        ...layer,
                        visible: this.selectedLayers[this.layer.gis_layer_id].sublayers[layer.gis_id].visible
                    }
                })
            }
            if (!this.selectedLayerVisibility) {
                sublayers = this.layer.sublayers.map((layer) => {
                    return {
                        ...layer,
                        visible: false
                    }
                })
            }
            return {...this.layer, sublayers}
        },
        selectedLayerVisibility() {
            return this.selectedLayers[this.layer.gis_layer_id].visible;
        },
        description() {
            let name = `${this.$t(`ATLAS.LAYER_MANAGER.${this.layer.group.toUpperCase()}_LABEL`)} / `;
            name += this.layer.container_name ? `${this.layer.container_name} / ` : '';
            name += this.layer.name;
            return name;
        }
    },
    methods: {
      ...mapActions({
        showSublayer: 'atlas/layer-manager/showSublayer',
        hideSublayer: 'atlas/layer-manager/hideSublayer',
      }),
      toggleSublayer(checked, sublayerId) {
          const payload = {
            layerId: this.layer.gis_layer_id,
            sublayerId,
          };
          this[checked ? 'showSublayer' : 'hideSublayer'](payload);
        },
    },
}
</script>
<style lang="scss" scoped>
    .sublayer-container {
      padding: 1.25rem;
      overflow-y: auto;
      height: 100%;
      .sublayer-column-header {
        font-size: 0.825rem;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0 0 1rem 0;
      }
      .sublayer-item {
        line-height: 2.25rem;
        margin: 0;
        .sublayer-name {
          padding: 0;
          text-align: left;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .sublayer-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 0 0 1rem;
        text-align: right;
        .custom-control {
          height: 1rem;
        }
        i {
          margin-bottom: 0.3rem;
          color: #286AE4;
          font-size: 1rem;
          cursor: pointer;
          width: 2rem;
        }
      }
    }
</style>
