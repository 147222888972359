<template>
    <div class="atlas-sidebar">
        <div class="atlas-sidebar-header">
            <div class="atlas-sidebar-title">
                <portal-target name="atlas-sidebar-title"></portal-target>
            </div>
            <div class="atlas-sidebar-close" @click="hideSidebar">
                <i class="far fa-times-circle text-dark"></i>
            </div>
        </div>
        <intersection-manager v-show="isIntersectionManagerVisible"></intersection-manager>
        <router-view v-if="!isIntersectionManagerVisible"></router-view>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('atlas/sidebar');

import IntersectionManager from '@/modules/atlas/components/IntersectionManager';

export default {
    name: 'LeftSidebar',
    components: {
        IntersectionManager,
    },
    computed: {
        ...mapState(['isIntersectionManagerVisible']),
    },
    methods: {
        ...mapActions(['hide', 'hideIntersectionManager']),
        hideSidebar() {
            if (this.isIntersectionManagerVisible) {
                this.hideIntersectionManager();
            } else {
                this.hide();
            }
        }
    },
}
</script>
<style lang="scss">
    .atlas-sidebar {
        position: absolute;
        top:0;
        left:0;
        height: 100%;
        width: 450px;
        background-color: #F2F3F7;
        border-right: 1px solid lightgray;
        margin-left: -3px;
        box-shadow: 1px 4px 4px 0 rgba(0,0,0,0.16);
    }
    .atlas-sidebar-header {
        height: 3.75rem;
        padding: 0 1.5rem;
        display: flex;
        align-items: center;
        .atlas-sidebar-title {
            font-weight: 500;
        }
        .atlas-sidebar-close {
            margin-left: auto;
            cursor: pointer;
        }
    }
</style>