<template>
    <div class="interest-areas">
        <portal to="atlas-sidebar-title">
            <span>{{ $t('ATLAS.INTERSECTION_MANAGER.TITLE') }}</span>
        </portal>
        <div class="layer-container">
            <div class="coordinates" v-if="pointOfIntersection">
                {{ `${pointOfIntersection.x}, ${pointOfIntersection.y}` }}
            </div>
            <div v-if="intersectedLayers.length">
                <div v-for="layer in intersectedLayers" :key="layer.uid" class="info-container">
                    <div class="info-header">{{ `${$t('ATLAS.INTERSECTION_MANAGER.INFO_LAYER_TEXT')} ${layer.name}`}}</div>
                    <div class="info-body">
                        <div class="info-body-title font-weight-bold mb-2">{{ `${$t('ATLAS.INTERSECTION_MANAGER.DETAILS_TEXT')}:` }}</div>
                        <div class="row mb-2">
                            <div class="col-6">{{ $t('ATLAS.INTERSECTION_MANAGER.SIRUTA_LABEL') }}</div>
                            <div class="col-6">{{ layer.uat_id }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">{{ $t('ATLAS.INTERSECTION_MANAGER.COUNTY_NAME_LABEL') }}</div>
                            <div class="col-6">Cluj</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">{{ $t('ATLAS.INTERSECTION_MANAGER.COUNTY_INDICATIV_LABEL') }}</div>
                            <div class="col-6">CJ</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">{{ $t('ATLAS.INTERSECTION_MANAGER.UAT_LABEL') }}</div>
                            <div class="col-6">{{ layer.uat_name }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">{{ $t('ATLAS.INTERSECTION_MANAGER.APPROVAL_YEAR_LABEL') }}</div>
                            <div class="col-6">2014</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">{{ $t('ATLAS.INTERSECTION_MANAGER.YEAR_OF_EXTENSION_LABEL') }}</div>
                            <div class="col-6">{{ $t('ATLAS.INTERSECTION_MANAGER.NO_YEAR_OF_EXTENSION') }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12">{{ `${$t('ATLAS.INTERSECTION_MANAGER.URBANISM_REGULATION_LABEL')}:` }}</div>
                            <div class="col-12 pl-8" v-for="rlu_id in layer.urbanism_regulations" :key="rlu_id">{{ rlu_id }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'IntersectionManager',
    computed: {
        ...mapGetters({
            intersectedLayers: 'atlas/layer-intersection/layers',
            pointOfIntersection: 'atlas/layer-intersection/pointOfIntersection',
        }),
    },
    methods: {
        ...mapActions({
            initStore: 'atlas/layer-intersection/doInit',
        }),
    },
    created() {
        this.initStore();
    }
}
</script>
<style lang="scss" scoped>
    .layer-container {
        padding: 0;
        overflow: auto;
    }
    .coordinates {
        padding: 0 1.5rem;
        font-size: 1.5rem;
        color: #B5B5C3; //$gray-500
        margin-bottom: 1.5rem;
    }
    .info-container {
        padding: 1.5rem;
        border-bottom: 1px solid #B5B5C3;
    }
    .info-header {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }
</style>