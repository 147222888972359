<template>
  <div class="map-view-container">
    <div id="viewDiv" class="mb-8" ref="mapView"></div>
    <div id="scaleWidget" class="esri-widget">
      <label for="ddlScale">Scale 1: </label>
      <select name="ddlScale" id="ddlScale" @change="scaleMap">
        <option value="500">500</option>
        <option value="1000">1.000</option>
        <option value="2000">2.000</option>
        <option value="5000">5.000</option>
        <option value="10000">10.000</option>
        <option value="20000">20.000</option>
        <option value="25000">25.000</option>
        <option value="50000">50.000</option>
        <option value="100000">100.000</option>
        <option value="150000">150.000</option>
        <option value="300000">300.000</option>
        <option value="600000">600.000</option>
        <option value="1200000">1.200.000</option>
        <option value="2400000">2.400.000</option>
        <option value="4800000">4.800.000</option>
        <option value="9600000">9.600.000</option>
        <option value="19200000">19.200.000</option>
        <option value="38400000">38.400.000</option>
      </select>
    </div>
    <div
      id="measurementWidget"
      class="esri-widget"
    >
      <button
        class="action-button esri-icon-measure-line"
        :class="{'active': activeWidgetType === 'distance'}"
        id="distanceButton"
        type="button"
        title="Measure distance between two or more points"
        @click="setActiveWidget('distance')"
      ></button>
      <button
        class="action-button esri-icon-measure-area"
        :class="{'active': activeWidgetType === 'area'}"
        id="areaButton"
        type="button"
        title="Measure area"
        @click="setActiveWidget('area')"
      ></button>
    </div>
  </div>
</template>

<script>
import Map from "@arcgis/core/Map";
import Graphic from "@arcgis/core/Graphic";
import MapView from "@arcgis/core/views/MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Field from "@arcgis/core/layers/support/Field";
import esriConfig from "@arcgis/core/config";
import esriConfigJson from "@/config/esri.config.json";
import Extent from "@arcgis/core/geometry/Extent";
import Polygon from "@arcgis/core/geometry/Polygon";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import Expand from "@arcgis/core/widgets/Expand";
import Home from "@arcgis/core/widgets/Home";
import Zoom from "@arcgis/core/widgets/Zoom";
import DistanceMeasurement2D from "@arcgis/core/widgets/DistanceMeasurement2D";
import AreaMeasurement2D from "@arcgis/core/widgets/AreaMeasurement2D";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import Search from "@arcgis/core/widgets/Search";
import * as watchUtils from "@arcgis/core/core/watchUtils";
import * as identify from "@arcgis/core/rest/identify";
import IdentifyParameters from '@arcgis/core/rest/support/IdentifyParameters';
import { mapGetters, mapActions } from 'vuex';

let GIS = {};
const LODS = [
  {
    level: 20,
    resolution: 0.25,
    scale: 500,
  },
  {
    level: 19,
    resolution: 0.5,
    scale: 1000,
  },
  {
    level: 18,
    resolution: 0.1,
    scale: 2000,
  },
  {
    level: 17,
    resolution: 2.5,
    scale: 5000,
  },
  {
    level: 16,
    resolution: 5,
    scale: 10000,
  },
  {
    level: 15,
    resolution: 10,
    scale: 20000,
  },
  {
    level: 14,
    resolution: 12.5,
    scale: 25000,
  },
  {
    level: 13,
    resolution: 25,
    scale: 50000,
  },
  {
    level: 12,
    resolution: 50,
    scale: 100000,
  },
  {
    level: 11,
    resolution: 75,
    scale: 150000,
  },
  {
    level: 10,
    resolution: 152.87405657041106,
    scale: 300000,
  },
  {
    level: 9,
    resolution: 305.74811314055756,
    scale: 600000,
  },
  {
    level: 8,
    resolution: 611.4962262813797,
    scale: 1200000,
  },
  {
    level: 7,
    resolution: 1222.992452562495,
    scale: 2400000,
  },
  {
    level: 6,
    resolution: 2445.98490512499,
    scale: 4800000,
  },
  {
    level: 5,
    resolution: 4891.96981024998,
    scale: 9600000,
  },
  {
    level: 4,
    resolution: 9783.93962049996,
    scale: 19200000,
  },
  {
    level: 3,
    resolution: 19567.87924099992,
    scale: 38400000,
  },
];

export default {
  name: "AtlasMap",
  props: {},
  data() {
    return {
      view: null,
      mapView: null,
      activeWidget: null,
      activeWidgetType: '',
      layerList: {},
      identityPointSymbol: null,
      identityLineSymbol: null,
      identityPolygonSymbol: null,
      selectedConflicts: null,
      intersectionEventHandler: null,
    };
  },
  computed: {
    ...mapGetters({
      allLayers: 'atlas/layer-manager/visibleLayers',
      selectedLayers: 'atlas/layer-manager/selectedLayers',
      token: 'atlas/token/token',
    }),
  },
  methods: {
    ...mapActions({
      getGisToken: 'atlas/token/getGisToken',
      saveIntersectedLayers: 'atlas/layer-intersection/saveIntersectedLayers',
      savePointOfIntersection: 'atlas/layer-intersection/savePointOfIntersection',
      showIntersectionManager: 'atlas/sidebar/showIntersectionManager',
    }),
    loadMap() {
      this.selectedConflicts = new GraphicsLayer();
      const map = new Map({
        basemap: "topo",
        layers: [this.selectedConflicts],
      });

      let initialExtend = new Extent({
        xmin: 2235009.71520354,
        ymin: 5392339.09295755,
        xmax: 3337702.92266886,
        ymax: 6153183.22280151,
        spatialReference: {
          wkid: 102100,
        },
      });
      // assign map to this view
      const view = new MapView({
        container: this.$el,
        map: map,
        extent: initialExtend,
        ui: {
          components: ["attribution"], // removes default widgets except for attribution
        }
      });
      var zoom = new Zoom({
          view: view,
          layout: "vertical"
      });
      // add to view
      view.ui.add(zoom, "bottom-right");

      GIS.map = map;
      GIS.view = view;
      this.addBasemapGallery();
      this.addHome();
      this.addMeasurement();
      this.addScaleSelect();
      this.addIdentityParams();
      this.attachIntersectionEvent();
      this.addSearchWidget();
    },
    addBasemapGallery() {
      const view = GIS.view;
      let basemapGallery = new BasemapGallery({
        view: view,
        container: document.createElement("div"),
      });
      const bgExpand = new Expand({
        view: view,
        content: basemapGallery,
      });

      // close the expand whenever a basemap is selected
      basemapGallery.watch("activeBasemap", () => {
        const mobileSize =
          view.heightBreakpoint === "xsmall" ||
          view.widthBreakpoint === "xsmall";

        if (mobileSize) {
          bgExpand.collapse();
        }
      });
      view.ui.add(bgExpand, "bottom-right");
    },
    addScaleSelect() {
      GIS.view.constraints = {
        minZoom: 0,
        maxZoom: 20,
        lods: LODS,
      };

      watchUtils.whenTrue(GIS.view, "stationary", () => {
        const $el = document.getElementById("ddlScale");
        if ($el) {
          $el.value = parseInt(GIS.view.scale);
        }
      });

      GIS.view.ui.add("scaleWidget", "bottom-right");
    },
    addHome() {
      const homeBtn = new Home({
        view: GIS.view,
      });

      GIS.view.ui.add(homeBtn, "bottom-right");
    },
    addMeasurement() {
      const expandMeasurement = new Expand({
        view: GIS.view,
        content: document.getElementById("measurementWidget"),
        expandIconClass: "esri-icon-measure",
      });

      GIS.view.ui.add(expandMeasurement, "bottom-right");
    },
    scaleMap(ev) {
      GIS.view.scale = ev.target.value;
    },
    addIdentityParams() {
      this.identityPointSymbol = {
        type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
        style: "square",
        color: "blue",
        size: "8px",  // pixels
        outline: {  // autocasts as new SimpleLineSymbol()
            color: "#4FFCFF",
            width: 3  // points
        }
      };
      this.identityLineSymbol = {
        type: "simple-line",  // autocasts as new SimpleLineSymbol()
        color: "#4FFCFF",
        width: "2px",
        style: "short-dot"
      };
      this.identityPolygonSymbol = {
        type: "simple-fill",  // autocasts as new SimpleFillSymbol()
        color: [51, 51, 204, 0],
        style: "solid",
        outline: {  // autocasts as new SimpleLineSymbol()
            color: "#4FFCFF",
            width: 1
        }
      };
    },
    loadSelectedLayers() {
      Object.keys(this.selectedLayers)?.forEach((layerId) => {
        if (this.selectedLayers?.[layerId]?.visible) {
            this.loadSelectedLayer(layerId);
        }
      });
    },
    loadSelectedLayer(layerId) {
        const service_url = this.allLayers.find(l => l.gis_layer_id == layerId)?.feature_service_url;
        this.fetchLayers(layerId, this.token, service_url);
    },
    addLayer(layerInfo) {
      const layerId = layerInfo.gis_layer_id;
      if (this.layerList[layerId]) {
        const layer = this.layerList[layerId];
        if (Object.keys(layer).length) {
          Object.keys(layer).forEach((sublayerId) => {
            layer[sublayerId].visible = !!this.selectedLayers?.[layerId]?.sublayers?.[sublayerId]?.visible;
          })
        }
      } else {
        this.fetchLayers(layerId, this.token, layerInfo.feature_service_url);
      }
    },
    addSublayer({ layerId, sublayerId }) {
        if(!this.layerList[layerId]) {
            this.loadSelectedLayer(layerId);
        }
      this.layerList[layerId][sublayerId].visible = true;
    },
    removeSublayer({ layerId, sublayerId }) {
        if(!this.layerList[layerId]) {
            this.loadSelectedLayer(layerId);
        }
        this.layerList[layerId][sublayerId].visible = false;
    },
    fetchLayers(layerId, token, feature_service_url) {
      if(feature_service_url) {
        esriConfig.request.interceptors.push({
          // set the `urls` property to the URL of the FeatureLayer so that this
          // interceptor only applies to requests made to the FeatureLayer URL
          urls: feature_service_url,
          // use the BeforeInterceptorCallback to add token to query
          before: function (params) {
            params.requestOptions.query = params.requestOptions.query || {};
            params.requestOptions.query.token = token;
          },
        });
        const sublayerList = {};
        const sublayers = this.allLayers.find((el) => el.gis_layer_id === layerId)?.sublayers || [];
        for (let i = sublayers.length - 1; i >= 0; i--) {
          const featureLayer = new FeatureLayer({
            url: `${feature_service_url}`,
            layerId: i,
          });
          featureLayer.visible = this.selectedLayers[layerId]?.sublayers?.[i]?.visible ?? true;
          sublayerList[i] = featureLayer;
          GIS.map.add(featureLayer);
        }
        this.layerList[layerId] = sublayerList;
      } else {
        console.log(`Feature Service URL is missing from layerid: ${layerId}`)
      }
    },
    removeLayer(layerInfo) {
      const layerId = layerInfo.gis_layer_id;
      const layer = this.layerList[layerId];
      if (layer && Object.keys(layer).length) {
        Object.keys(layer).forEach((sublayerId) => {
          layer[sublayerId].visible = false;
        });
      }
    },
    setActiveWidget(type) {
      if (this.activeWidgetType === type) {
        GIS.view.ui.remove(this.activeWidget);
        this.activeWidget.destroy();
        this.activeWidget = null;
        this.activeWidgetType = '';
        return;
      }
      switch(type) {
        case 'distance':
          this.activeWidget = new DistanceMeasurement2D({
            view: GIS.view
          });
          // skip the initial 'new measurement' button
          this.activeWidget.viewModel.start();

          GIS.view.ui.add(this.activeWidget, "bottom-right");
          this.activeWidgetType = 'distance';
        break;
        case 'area':
          this.activeWidget = new AreaMeasurement2D({
              view: GIS.view,
              unit: 'metric',
          });

          // skip the initial 'new measurement' button
          this.activeWidget.viewModel.start();

          GIS.view.ui.add(this.activeWidget, "bottom-right");
          this.activeWidgetType = 'area';
        break;
      }
    },
    executeIntersectionIdentification(event) {
      // Open layer intersection manager sidebar
      this.showIntersectionManager();
      const that = this;
      const xMapPoint = event.mapPoint.x;
      const yMapPoint = event.mapPoint.y;
      this.savePointOfIntersection({
        x: event.mapPoint.latitude,
        y: event.mapPoint.longitude,
      });
      const allVisibleLayers = Object.entries(this.layerList)
        .map((el) => ({
          id: el[0],
          sublayers: Object.values(el[1]).filter((layer) => layer.visible),
        })) || [];

      const numberOfVisibleLayers = Object.values(this.layerList).reduce((acc, el) => acc + Object.keys(el).length, 0);

      this.selectedConflicts.removeAll();
      const tolerance = 2;
      const rings = [
        [
          [xMapPoint - tolerance, yMapPoint - tolerance],
          [xMapPoint - tolerance, yMapPoint + tolerance],
          [xMapPoint + tolerance, yMapPoint + tolerance],
          [xMapPoint + tolerance, yMapPoint - tolerance],
          [xMapPoint - tolerance, yMapPoint - tolerance]  // same as first vertex
        ]
      ];

      const polygon = new Polygon({
        hasZ: true,
        hasM: true,
        rings: rings,
        spatialReference: { wkid: 102100 }
      });

      let layerSearchedCount = 0;
      for (let i = 0; i < allVisibleLayers.length; i++) {
        const currentLayerId = allVisibleLayers[i].id;
        const visibleLayers = allVisibleLayers[i]?.sublayers || [];
        for (let j = 0; j < visibleLayers.length; j++) {
          visibleLayers[j].queryFeatures({
              //query object
              geometry: polygon,
              spatialRelationship: "intersects",
              returnGeometry: true,
              outFields: ["*"],
          })
          .then((featureSet) => {
              layerSearchedCount++;
              let resultFeatures = featureSet.features;
              if (resultFeatures.length > 0) {
                for (let k = 0; k < resultFeatures.length; k++) {
                  let mySymbol = null;
                  switch (resultFeatures[k].geometry.type) {
                    case "point":
                      mySymbol = that.identityPointSymbol;
                      break;
                    case "polyline":
                      mySymbol = that.identityLineSymbol;
                      break;
                    case "polygon":
                      mySymbol = that.identityPolygonSymbol;
                      break;
                  }
                  let graphic = new Graphic({
                    geometry: resultFeatures[k].geometry,
                    symbol: mySymbol,
                    attributes: {
                      uid: resultFeatures[k].layer.id,
                      layer_name: resultFeatures[k].attributes.Layer,
                      gis_layer_id: currentLayerId,
                      sublayer_id: resultFeatures[k].layer?.layerId,
                      rlu_id: resultFeatures[k].attributes?.RLU_ID,
                    }
                  });
                  that.selectedConflicts.graphics.add(graphic);
                }
              }
              if (layerSearchedCount === numberOfVisibleLayers) {
                let intersectedLayers = [];
                for (let p = 0; p < that.selectedConflicts.graphics.items.length; p++) {
                  let myItem = that.selectedConflicts.graphics.items[p];
                  const { uid, gis_layer_id, layer_name, sublayer_id, rlu_id  } = myItem.attributes;
                  const layerData = this.allLayers.find((el) => el.gis_layer_id === gis_layer_id);
                  if (layerData) {
                    const matchedIntersectionLayer = intersectedLayers.find((el) => el.gis_layer_id === gis_layer_id);
                    const matchedSublayer = layerData.sublayers.find((el) => el.gis_id === sublayer_id);

                    if (matchedIntersectionLayer) {
                      const unmatchedIntersectionLayers = intersectedLayers.filter((el) => el.gis_layer_id !== gis_layer_id);
                      matchedIntersectionLayer.sublayers.push(matchedSublayer);
                      if (!matchedIntersectionLayer.urbanism_regulations.includes(rlu_id)) {
                        matchedIntersectionLayer.urbanism_regulations.push(rlu_id);
                      }
                      intersectedLayers = [
                        ...unmatchedIntersectionLayers,
                        {
                          ...matchedIntersectionLayer,
                        },
                      ];
                    } else {
                      const formattedLayerData = {
                        ...layerData,
                        sublayers: [],
                        urbanism_regulations: [],
                      }
                      if (matchedSublayer) {
                        formattedLayerData.sublayers.push(matchedSublayer);
                      }
                      if (rlu_id) {
                        formattedLayerData.urbanism_regulations.push(rlu_id);
                      }
                      intersectedLayers.push(formattedLayerData);
                    }
                  }
                }
                this.saveIntersectedLayers(intersectedLayers);
              }
          });
        }
      }
    },
    attachIntersectionEvent() {
      GIS.view?.when(() => {
        if (!this.intersectionEventHandler) {
          this.intersectionEventHandler = GIS.view.on('click', this.executeIntersectionIdentification);
          // Set the parameters for the identify
          const params = new IdentifyParameters();
          params.tolerance = 3;
          params.layerIds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
          params.layerOption = "visible";
          params.width = GIS.view.width;
          params.height = GIS.view.height;
        }
      });
    },
    addSearchWidget() {
        const view = GIS.view;
        const search = new Search({
            view: view,
        });
        view.ui.add(search, { position: "bottom-right"});
    }
  },
  async created() {
    await this.getGisToken();
    await this.loadMap();
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'atlas/layer-manager/setLayers') {
        this.loadSelectedLayers();
      }
      if (mutation.type === 'atlas/layer-manager/setVisibleLayer') {
        this.addLayer(mutation.payload);
      }
      if (mutation.type === 'atlas/layer-manager/removeVisibleLayer' ||
        mutation.type === 'atlas/layer-manager/removeLayer') {
        this.removeLayer(mutation.payload);
      }
      if (mutation.type === 'atlas/layer-manager/setVisibleSublayer') {
        this.addSublayer(mutation.payload);
      }
      if (mutation.type === 'atlas/layer-manager/removeVisibleSublayer') {
        this.removeSublayer(mutation.payload);
      }
      if (mutation.type === 'atlas/layer-manager/setVisibleLayers') {
        mutation.payload.forEach((layer) => {
          this.addLayer(layer);
        });
      }
      if (mutation.type === 'atlas/layer-manager/removeVisibleLayers') {
        mutation.payload.forEach((layer) => {
          this.removeLayer(layer);
        });
      }
    })
  },
};
</script>

<style lang="scss">
/* esri styles */
@import url("https://js.arcgis.com/4.20/esri/themes/light/main.css");
.esri-view .esri-view-surface--inset-outline:focus::after {
  outline: 0;
}
.map-view-container {
  height: inherit;

  #viewDiv {
    height: inherit;
  }

  .esri-popup .esri-popup-header .esri-title {
    font-size: 18px;
    font-weight: bolder;
  }

  .esri-popup .esri-popup-body .esri-popup-content {
    font-size: 14px;
  }

  #measurementWidget {
    background: #fff;
    padding: 10px;
  }

  .action-button {
    font-size: 16px;
    background-color: transparent;
    border: 1px solid #d3d3d3;
    color: #6e6e6e;
    height: 32px;
    width: 32px;
    text-align: center;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  }

  .action-button:hover,
  .action-button:focus {
    background: #0079c1;
    color: #e4e4e4;
  }

  .active {
    background: #0079c1;
    color: #e4e4e4;
  }
}
</style>
